import React, { Component } from 'react'

import SEO from '../components/SEO/SEO'
import Layout from '../components/layout'
import News from '../components/news/news'

import { Section, Container } from '../components/styled/layout'
import { Title } from '../components/styled/typography'

class BloggPage extends Component {
  state = {}
  render() {
    return (
      <Layout>
        <SEO title="Blogg" />
        <Section>
          <Container>
            <Title style={{ marginBottom: '5vmax' }}>
              Blogg. <span>Följ vårt arbete.</span>
            </Title>
            <News />
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default BloggPage
